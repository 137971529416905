import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import styled from "styled-components/macro"
import Container from "../components/common/Container"
import ContactForm from "../components/ContactForm"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useActs } from "../hooks/acts"
import { useBlogAssets } from "../hooks/blog"
import { pxToRem } from "../utils"

const ActDescription = styled.p`
  font-size: 1.75rem;
  margin-bottom: 6.25rem;
  text-align: center;
`
const Group = styled.div`
  margin-bottom: ${pxToRem(20)};
  display: ${props => (props.show ? "block" : "none")};
`
const ActGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
  /* margin-bottom: 6.25rem; */
`
const ActTitleContainer = styled.div`
  display: flex;
  justify-self: center;
  justify-content: center;
  align-self: center;
  align-items: center;
  min-width: 140px;
  height: 60px;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(4, 4, 21, 1) 0%,
    rgba(4, 4, 21, 1) 26.46%,
    rgba(12, 12, 74, 1) 80.42%,
    rgba(0, 0, 0, 1) 100%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#040415',endColorstr='#000000' , GradientType=1);
  transform: rotate(90deg);
  grid-area: title;

  @media all and (min-width: 414px) {
    min-width: 275px;
  }

  @media all and (min-width: 768px) {
    min-width: 467px;
  }
`
const Act = styled(Link)`
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-areas: "title image";
  clip-path: polygon(0 0, 100% 0, 100% 60%, 0% 100%);

  &:nth-child(even) {
    grid-template-columns: 1fr 60px;
    grid-template-areas: "image title";
    clip-path: polygon(0 40%, 100% 0, 100% 100%, 0 100%);
    margin-top: -16.24%;

    ${ActTitleContainer} {
      transform: rotate(-90deg);
    }

    @media all and (min-width: 414px) {
      margin-top: -107px;
    }

    @media all and (min-width: 768px) {
      margin-top: -187px;
    }
  }

  &:hover {
    text-decoration: none;
  }
`
const ActTitle = styled.h2`
  margin: 0;
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;

  @media all and (min-width: 768px) {
    font-size: 1.625rem;
  }
`
const Video = styled.video`
  height: 140px;
  object-fit: cover;
  width: 100%;

  @media all and (min-width: 414px) {
    height: 275px;
  }

  @media all and (min-width: 768px) {
    height: 467px;
  }
`
const HeroVideo = styled.video`
  height: 300px;
  object-fit: cover;
  width: 100%;
  margin-bottom: 6.25rem;
`
const ContactWrapper = styled.section`
  background-color: #000;
  padding: ${pxToRem(100)} 0;
  color: #fff;
`
const ContactTitle = styled.h2`
  font-weight: 400;
  font-size: ${pxToRem(60)};
  font-family: "Teko", sans-serif;
  text-transform: uppercase;
  text-align: center;
  line-height: 0.9;
  margin-top: 0;
  margin-bottom: ${pxToRem(30)}; ;
`
const ContactAltTitle = styled.h3`
  font-size: ${pxToRem(24)};
  font-weight: 300;
  text-align: center;
  margin-top: 0;
  margin-bottom: ${pxToRem(40)};

  @media all and (min-width: 1025px) {
    font-size: ${pxToRem(28)};
  }
`
const Strong = styled.span`
  font-weight: 700;
`
const AltTag = styled.a`
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  font-style: italic;
`
const MoreButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${pxToRem(70)};
  cursor: pointer;
`
const PlusWrapper = styled.div`
  display: flex;
  padding: 10px;
  border: 2px solid #fff;
  border-radius: 50%;
  margin-right: ${pxToRem(20)};
`
const Plus = styled.div`
  --b: 4px; /* the thickness */
  width: 20px; /* the size */
  aspect-ratio: 1;
  /* border: 2px solid #fff; */
  background: conic-gradient(
      from 90deg at var(4px) var(4px),
      transparent 90deg,
      #fff 0
    )
    calc(100% + var(4px) / 2) calc(100% + var(4px) / 2) / calc(50% + var(4px))
    calc(50% + var(4px));
  display: inline-block;
  /* border-radius: 50%; */
  border: none;
`
const MoreButton = styled.a`
  font-size: ${pxToRem(28)};
  font-weight: 300;
  font-family: "Montserrat", sans-serif;

  &:hover {
    text-decoration: none;
  }
`
const ActGroups = styled.div``
const ActCount = styled.span`
  width: 100%;
  display: inline-block;
  text-align: center;
  font-size: ${pxToRem(20)};
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
`

const ActPage = () => {
  const [displayedActGroups, setDisplayedActGroups] = useState(0)

  const acts = useActs()
  const { video } = useBlogAssets()

  const segmentedActs = acts
    .reduce((acc, i, idx) => {
      return !(idx % 6) ? [...acc, [i]] : [...acc, [...acc[acc.length - 1], i]]
    }, [])
    .filter((act, idx, array) => act.length === 6 || array.length === idx + 1)
  const groupLimit = segmentedActs.length

  return (
    <Layout>
      {/* <HeroVideo autoPlay muted loop playsInline preload="metadata">
        <source src={video} type="video/mp4" />
      </HeroVideo> */}
      <StaticImage
        src="../images/flipunit__all-show__hero2.jpeg"
        alt="Dave drinking"
        style={{ height: 300, width: "100%", marginBottom: "6.25rem" }}
        objectFit="cover"
        // objectPosition="0% 0%"
      />
      <Container>
        <ActDescription className="wow animate__fadeIn">
          Check out all of our athletes who are ready to perform an exceptional
          show and be the highlight of your event! We offer a wide range of
          extreme sports from freestyle acrobats through BMX riders to pro
          cheerleaders. "This is our life, our spirit and we perform with 110%
          dedication!"
        </ActDescription>
        <ActGroups>
          {segmentedActs.map((group, idx) => (
            <Group key={idx} show={idx <= displayedActGroups}>
              <ActGrid>
                {group.map((act, idx) => (
                  <Act
                    key={idx}
                    to={act.link || act.slug}
                    className="wow animate__fadeIn"
                    data-wow-delay={`${idx * 0.1}s`}
                  >
                    <ActTitleContainer>
                      <ActTitle>{act.title}</ActTitle>
                    </ActTitleContainer>
                    <Video
                      key={act.video.publicURL}
                      autoPlay
                      muted
                      loop
                      playsInline
                      poster={
                        act.cover?.src.gatsbyImageData.images.fallback.src
                      }
                      preload="metadata"
                    >
                      <source src={act.video.publicURL} type="video/mp4" />
                    </Video>
                  </Act>
                ))}
              </ActGrid>
            </Group>
          ))}
          <ActCount>{`${segmentedActs
            .slice(0, displayedActGroups + 1)
            .reduce((acc, acts) => (acc += acts.length), 0)}/${
            acts.length
          }`}</ActCount>
        </ActGroups>
        {groupLimit !== displayedActGroups ? (
          <MoreButtonWrapper onClick={() => setDisplayedActGroups(groupLimit)}>
            <PlusWrapper>
              <Plus></Plus>
            </PlusWrapper>
            <MoreButton>All Acts</MoreButton>
          </MoreButtonWrapper>
        ) : null}
        <ContactWrapper>
          <Container>
            <ContactTitle className="wow animate__fadeIn">
              If any of these shows grabbed your attention then get in touch!
            </ContactTitle>
            <ContactAltTitle
              className="wow animate__fadeIn"
              data-wow-delay=".2s"
            >
              <Strong>Fill the form</Strong> below or message us at{" "}
              <AltTag href="mailto:show@flipunitprod.com">
                show@flipunitprod.com
              </AltTag>
            </ContactAltTitle>
            <ContactForm
              mailchimpTags={["All shows contact oldal"]}
              formName="All extreme sports fix"
            />
          </Container>
        </ContactWrapper>
      </Container>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="EXTREME SPORT SHOWS | Wide selection of extreme entertainers"
    slug="/all-extreme-sport-shows"
    description="Check out all the extreme sport athletes we provide as an entertainment agency, specialized in extreme sports. Shows can be combined into a megashow."
  />
)

export default ActPage
