import { useStaticQuery, graphql } from "gatsby"

export const useActs = () => {
  const collection = useStaticQuery(
    graphql`
      query acts {
        acts: allMarkdownRemark(
          filter: {
            fileAbsolutePath: { regex: "/acts/" }
            frontmatter: { active: { ne: false } }
          }
          sort: { fields: frontmatter___order, order: ASC }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                cover {
                  src: childImageSharp {
                    gatsbyImageData(formats: [JPG], width: 1200)
                  }
                }
                video {
                  publicURL
                }
                link
                slug
              }
            }
          }
        }
      }
    `
  )

  return collection.acts.edges.reduce(
    (acc, act) => [
      ...acc,
      {
        id: act.node.id,
        ...(act.node ? { ...act.node.frontmatter } : {}),
      },
    ],
    []
  )
}
